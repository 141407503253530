html,
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background-color: black;
    color: white;
    font-size: 1.2rem;
}

.container {
    width: 70%;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container img {
    margin-bottom: 20px;
}

/* .container button {
    margin-top: 20px;
    padding: 10px 70px;
    border: 2px solid white;
    border-radius: 3px;
    background-color: black;
    color: white;
    text-align: center;
    transition: 0.4s all ease;
} */

button {
    margin-top: 20px;
    padding: 10px 70px;
    border: 1px solid black;
    border-radius: 3px;
    background-color: white;
    color: black;
    text-align: center;
    transition: 0.2s all ease;
    font-size: 20px;
}

/* .container button:hover {
    background-color: white;
    color: black;
    /* box-shadow: 5px 10px white;
    border: 2px solid white;
    cursor: pointer;
} */

button:hover {
    background-color: black;
    color: white;
    box-shadow: 5px 10px white;
    border: 1px solid white;
    cursor: pointer;
}

.question-container h1 {
    font-size: 24px;
    margin-bottom: 40px;
}

.option {
    margin: 10px;
}

.option input {
    accent-color: yellow;
}

.option label {
    margin-left: 10px;
}

/* .row {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}